import {
  AGENCY_PARENT_DETAIL_REQUEST,
  AGENCY_PARENT_DETAIL_SUCCESS,
  AGENCY_PARENT_DETAIL_FAIL,
  AGENCY_PARENT_DETAIL_CREATE_REQUEST,
  AGENCY_PARENT_DETAIL_CREATE_SUCCESS,
  AGENCY_PARENT_DETAIL_CREATE_FAIL,
  FIND_AGENCY_PARENT_DETAIL_REQUEST,
  FIND_AGENCY_PARENT_DETAIL_SUCCESS,
  FIND_AGENCY_PARENT_DETAIL_FAIL,
  UPDATE_AGENCY_PARENT_DETAIL_REQUEST,
  UPDATE_AGENCY_PARENT_DETAIL_SUCCESS,
  UPDATE_AGENCY_PARENT_DETAIL_FAIL,
  DELETE_AGENCY_PARENT_DETAIL_REQUEST,
  DELETE_AGENCY_PARENT_DETAIL_SUCCESS,
  DELETE_AGENCY_PARENT_DETAIL_FAIL,
  CLEAR_ERROR,
} from "../../constants/AgencyMaintenance/AgencyParentDetailConstant";

// create, update, delete and get all
export const getAgencyParentDetailReducer = (state = { agencyParentDetails: [] }, action) => {
  switch (action.type) {
    case AGENCY_PARENT_DETAIL_REQUEST:
    case AGENCY_PARENT_DETAIL_CREATE_REQUEST:
    case UPDATE_AGENCY_PARENT_DETAIL_REQUEST:
    case DELETE_AGENCY_PARENT_DETAIL_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
        error:null

      };
    case AGENCY_PARENT_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        agencyParentDetails: action.payload,
        message: null,
        status: null,
      };

    case AGENCY_PARENT_DETAIL_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        agencyParentDetails: [...state.agencyParentDetails, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_AGENCY_PARENT_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        agencyParentDetails: [
          ...state.agencyParentDetails.filter(
            (item) => item.agencyId !== action.payload.agencyId
          ),
          action.payload,
        ].sort((pre, post) => pre.agencyId - post.agencyId),
        message: action.message,
        status: action.status,
      };

    case DELETE_AGENCY_PARENT_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        agencyParentDetails: [
          ...state.agencyParentDetails.filter((item) => item.agencyId !== action.payload),
        ],
        message: action.message,
        status: action.status,
      };

    case AGENCY_PARENT_DETAIL_FAIL:
      return {
        ...state,
        loading: false,
        agencyParentDetails: [...state.agencyParentDetails],
        error: action.payload,
      };

    case AGENCY_PARENT_DETAIL_CREATE_FAIL:
    case UPDATE_AGENCY_PARENT_DETAIL_FAIL:
      return {
        ...state,
        loading: false,
        agencyParentDetails: [...state.agencyParentDetails],
        message: action.message,
        status: action.status,
      };
      
    case DELETE_AGENCY_PARENT_DETAIL_FAIL:
      return {
        ...state,
        loading: false,
        agencyParentDetails: [...state.agencyParentDetails],
        error: action.payload,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};

// find one
export const findAgencyParentDetailReducer = (state = { agencyParentDetail: {} }, action) => {
  switch (action.type) {
    case FIND_AGENCY_PARENT_DETAIL_REQUEST:
      return {
        loading: true,
        agencyParentDetail: {},
      };
    case FIND_AGENCY_PARENT_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        agencyParentDetail: action.payload,
      };

    case FIND_AGENCY_PARENT_DETAIL_FAIL:
      return {
        ...state,
        loading: false,
        agencyParentDetail: {},
        error: action.payload,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
      };

    default:
      return state;
  }
};