// AGENCY_PARENT_DETAIL create/post
export const AGENCY_PARENT_DETAIL_CREATE_REQUEST = "AGENCY_PARENT_DETAIL_CREATE_REQUEST";
export const AGENCY_PARENT_DETAIL_CREATE_SUCCESS = "AGENCY_PARENT_DETAIL_CREATE_SUCCESS";
export const AGENCY_PARENT_DETAIL_CREATE_FAIL = "AGENCY_PARENT_DETAIL_CREATE_FAIL"

// AGENCY_PARENT_DETAIL get
export const AGENCY_PARENT_DETAIL_REQUEST = "AGENCY_PARENT_DETAIL_REQUEST";
export const AGENCY_PARENT_DETAIL_SUCCESS = "AGENCY_PARENT_DETAIL_SUCCESS";
export const AGENCY_PARENT_DETAIL_FAIL = "AGENCY_PARENT_DETAIL_FAIL"


// AGENCY_PARENT_DETAIL find
export const FIND_AGENCY_PARENT_DETAIL_REQUEST = "FIND_AGENCY_PARENT_DETAIL_REQUEST";
export const FIND_AGENCY_PARENT_DETAIL_SUCCESS = "FIND_AGENCY_PARENT_DETAIL_SUCCESS";
export const FIND_AGENCY_PARENT_DETAIL_FAIL = "FIND_AGENCY_PARENT_DETAIL_FAIL"

// AGENCY_PARENT_DETAIL update/put
export const UPDATE_AGENCY_PARENT_DETAIL_REQUEST = "UPDATE_AGENCY_PARENT_DETAIL_REQUEST";
export const UPDATE_AGENCY_PARENT_DETAIL_SUCCESS = "UPDATE_AGENCY_PARENT_DETAIL_SUCCESS";
export const UPDATE_AGENCY_PARENT_DETAIL_FAIL = "UPDATE_AGENCY_PARENT_DETAIL_FAIL"

// AGENCY_PARENT_DETAIL update/put
export const DELETE_AGENCY_PARENT_DETAIL_REQUEST = "DELETE_AGENCY_PARENT_DETAIL_REQUEST";
export const DELETE_AGENCY_PARENT_DETAIL_SUCCESS = "DELETE_AGENCY_PARENT_DETAIL_SUCCESS";
export const DELETE_AGENCY_PARENT_DETAIL_FAIL = "DELETE_AGENCY_PARENT_DETAIL_FAIL"

export const CLEAR_ERROR = "CLEAR_ERROR"